const purchaseChildRoutes = (prefix) => [
  {
    path: "requesition",
    name: prefix + "_requesition_overview",
    component: () => import("@/views/purchase/requesition/RequesitionOverview.vue"),
  },
  {
    path: "requesition/create",
    name: prefix + "_requesition_add",
    component: () => import("@/views/purchase/requesition/RequesitionCreate.vue"),
  },
  {
    path: "requesition/show/:id",
    name: prefix + "_requesition_show",
    component: () => import("@/views/purchase/requesition/RequesitionShow.vue"),
  },
  {
    path: "order",
    name: prefix + "_order_overview",
    component: () => import("@/views/purchase/order/OrderOverview.vue"),
  },
  {
    path: "order/create",
    name: prefix + "_order_add",
    component: () => import("@/views/purchase/order/OrderCreate.vue"),
  },
  {
    path: "order/show/:id",
    name: prefix + "_order_show",
    component: () => import("@/views/purchase/order/OrderShow.vue"),
  },
  {
    path: "receipt",
    name: prefix + "_receipt_overview",
    component: () => import("@/views/purchase/receipt/ReceiptOverview.vue"),
  },
  {
    path: "receipt/create",
    name: prefix + "_receipt_add",
    component: () => import("@/views/purchase/receipt/ReceiptCreate.vue"),
  },
  {
    path: "receipt/show/:id",
    name: prefix + "_receipt_show",
    component: () => import("@/views/purchase/receipt/ReceiptShow.vue"),
  },
  {
    path: "payment",
    name: prefix + "_payment_overview",
    component: () => import("@/views/purchase/payment/PaymentOverview.vue"),
  },
  {
    path: "payment/create",
    name: prefix + "_payment_add",
    component: () => import("@/views/purchase/payment/PaymentCreate.vue"),
  },
  {
    path: "payment/show/:id",
    name: prefix + "_payment_show",
    component: () => import("@/views/purchase/payment/PaymentShow.vue"),
  },
];

export default purchaseChildRoutes;
