import { createRouter, createWebHistory } from "vue-router";
import bookingChildRoutes from "@/router/children/bookingChildRoutes";
import dashboardChildRoutes from "@/router/children/dashboardChildRoutes";
import jobChildRoutes from "@/router/children/jobChildRoutes";
import masterChildRoutes from "@/router/children/masterChildRoutes";
import adminChildRoutes from "@/router/children/adminChildRoutes";
import financeChildRoutes from "@/router/children/financeChildRoutes";
import contractChildRoutes from "@/router/children/contractChildRoutes";
import purchaseChildRoutes from "@/router/children/purchaseChildRoutes";
import assetsChildRoutes from "@/router/children/assetsChildRoutes";
import driversChildRoutes from "@/router/children/driversChildRoutes";
import reportChildRoutes from "@/router/children/reportChildRoutes";

const defaultTitle = "in\u2022Fest";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: dashboardChildRoutes("home"),
    meta: {
      title: "Home",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/LoginPage.vue"),
    meta: {
      title: "Sign In",
    },
  },
  {
    path: "/booking",
    name: "booking",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: bookingChildRoutes("booking"),
    meta: {
      title: "Booking",
      requiresAuth: true,
    },
  },
  {
    path: "/job_order",
    name: "job_order",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: jobChildRoutes("job_order"),
    meta: {
      title: "Job Order",
      requiresAuth: true,
    },
  },
  {
    path: "/finance",
    name: "finance",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: financeChildRoutes("finance"),
    meta: {
      title: "Finance",
      requiresAuth: true,
    },
  },
  {
    path: "/contract",
    name: "contract",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: contractChildRoutes("contract"),
    meta: {
      title: "Contract",
      requiresAuth: true,
    },
  },
  {
    path: "/assets",
    name: "assets",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: assetsChildRoutes("assets"),
    meta: {
      title: "Assets",
      requiresAuth: true,
    },
  },
  {
    path: "/drivers",
    name: "drivers",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: driversChildRoutes("drivers"),
    meta: {
      title: "Drivers",
      requiresAuth: true,
    },
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: purchaseChildRoutes("purchase"),
    meta: {
      title: "Purchase",
      requiresAuth: true,
    },
  },
  {
    path: "/master",
    name: "master",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: masterChildRoutes("master"),
    meta: {
      title: "Master",
      requiresAuth: true,
    },
  },
  {
    path: "/administration",
    name: "adminid",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: adminChildRoutes("adminid"),
    meta: {
      title: "Administration",
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "report",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: reportChildRoutes("report"),
    meta: {
      title: "Report",
      requiresAuth: true,
    },
  },

  {
    // Redirect any unknown route to "/login"
    path: "/:catchAll(.*)",
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated || isAuthenticated.expiry < Date.now()) {
      next("/login");
    } else {
      document.title = `${defaultTitle} | ${to.meta.title}`;
      updateSessionExpiry();
      next();
    }
  } else {
    document.title = `${defaultTitle} | ${to.meta.title}`;
    next();
  }
});

function updateSessionExpiry() {
  const now = new Date();
  const expiry = now.getTime() + 2 * 60 * 60 * 1000;  
  const isAuthenticated = {
    value: true,
    expiry: expiry,
  };
  localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
}

export default router;
