// adminsLocation.js
import axios from "axios";

const state = {
  dataAdminOffice: [],
  dataAdminTax: [],
  dataCompanyTax: [],
  // Flags to track whether data has been fetched
  isDataAdminOfficeLoaded: false,
  isDataAdminTaxLoaded: false,
  isDataCompanyTaxLoaded: false,
};

const actions = {
  fetchDataAdminOffice({ commit, state }) {
    if (!state.isDataAdminOfficeLoaded) {
      axios
        .get("api/webui/admins/office", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminOffice", response.data.data);
          commit("setDataAdminOfficeLoaded", true);
        })
        .catch((error) => {
          console.error("Error fetching office data:", error);
        });
    }
  },
  fetchDataAdminTax({ commit, state }, office_id) {
    if (!state.isDataAdminTaxLoaded) {
      axios
        .get("api/webui/admins/office/tax", {
          params: {
            office_id: office_id,
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminTax", response.data.data);
          commit("setDataAdminTaxLoaded", true);
        })
        .catch((error) => {
          console.error("Error fetching Tax data:", error);
        });
    }
  },
  fetchDataCompanyTax({ commit, state }) {
    if (!state.isDataCompanyTaxLoaded) {
      axios
        .get("api/webui/admins/company/tax_type", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataCompanyTax", response.data.data);
          commit("setDataCompanyTaxLoaded", true);
        })
        .catch((error) => {
          console.error("Error fetching Tax data:", error);
        });
    }
  },
};

const mutations = {
  setDataAdminOffice(state, data) {
    state.dataAdminOffice = data;
  },
  setDataAdminTax(state, data) {
    state.dataAdminTax = data;
  },
  setDataCompanyTax(state, data) {
    state.dataCompanyTax = data;
  },
  setDataAdminOfficeLoaded(state, isLoaded) {
    state.isDataAdminOfficeLoaded = isLoaded;
  },
  setDataAdminTaxLoaded(state, isLoaded) {
    state.isDataAdminTaxLoaded = isLoaded;
  },
  setDataCompanyTaxLoaded(state, isLoaded) {
    state.isDataCompanyTaxLoaded = isLoaded;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
